import { NextRouter, useRouter } from 'next/router';
import { ReactNode, useEffect } from 'react';
import { AnyVariables, useQuery, UseQueryState } from 'urql';

import { User } from '@/types/user.type';

import { useComponentWillMount } from 'lib/hooks';
import { CURRENT_USER } from 'lib/queries';

// Can always go to these paths
const homePath = /^\/$/;

// Can only go to these paths if logged out
const loggedOutPaths = /^\/auth/;

// Can only go to these paths if user is an admin
const adminPaths = /^(\/documentation)/;

// Can only go to these paths if you have access to the slug
const managePaths = /^\/manage-data\/.+/;

// Redirect the user if they are somewhere they shouldn't be
const redirect =
  (
    result: UseQueryState<
      {
        currentUser: User;
      },
      AnyVariables
    >,
    router: NextRouter,
    /**
     * serverRedirect isn't available during static builds
     */
    serverRedirect?: any,
  ) =>
  () => {
    const user = result?.data?.currentUser;

    if (
      result.fetching ||
      (!user && !result.error) ||
      (!serverRedirect && typeof window === 'undefined')
    ) {
      return;
    }

    const path = router.pathname;
    const slug = router.query?.slug;
    const goToUrl = serverRedirect || router.push;
    const isAdmin = user?.isAdmin;

    // Has access to at least one LA as an editor
    const hasEditorAccess = user?.access?.length || isAdmin;
    const userSlugs = (user?.access || []).map((a) => a.localAuthority.slug);

    // Has access to this slug
    const hasSlugAccess =
      !slug || isAdmin || userSlugs.includes(slug as string);

    // If they're logged in and shouldn't be here, send to dashboard
    if (user?.id) {
      const shouldNotBeHere =
        path.match(loggedOutPaths) ||
        // They're in the admin bit and they're not an admin
        (path.match(adminPaths) && !isAdmin) ||
        // They're on the homepage or login page
        path.match(homePath) ||
        // They're in manage data, but don't have access to the slug
        (path.match(managePaths) && !(hasEditorAccess && hasSlugAccess));

      if (shouldNotBeHere) {
        goToUrl(`${router.basePath}/dashboard`);
      }
    } else {
      // If they're logged out and shouldn't be here, send to login
      const shouldNotBeHere =
        !path.match(homePath) && !path.match(loggedOutPaths);

      if (shouldNotBeHere) {
        goToUrl(`${router.basePath}/auth/login`);
      }
    }
  };

// Check we are logged in and redirect if on wrong route
function RouteAccess({
  serverRedirect,
  children,
}: {
  readonly serverRedirect: any;
  readonly children?: ReactNode;
}) {
  const router = useRouter();
  const [result] = useQuery<{ currentUser: User }>({
    query: CURRENT_USER,
  });

  const userId = result?.data?.currentUser?.id;

  // Kick them in/out if there's no or a user
  // Once before load
  useComponentWillMount(redirect(result, router, serverRedirect));
  // And any time the userId changes
  useEffect(() => {
    redirect(result, router);
  }, [userId, router, result]);

  return <>{children}</>;
}

export default RouteAccess;
