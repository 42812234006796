import Link from 'next/link';
import { useRouter } from 'next/router';
import { useQuery } from 'urql';

import LinkArrow from 'components/controls/LinkArrow/LinkArrow';
import { LOCAL_AUTHORITY } from 'lib/queries';

export default function RecentlyViewedItem({
  slug,
}: {
  readonly slug: string;
}) {
  const router = useRouter();
  const [{ data }] = useQuery({
    query: LOCAL_AUTHORITY,
    variables: {
      slug,
    },
  });

  if (!data) {
    return null;
  }

  const la = data.localAuthority;

  return (
    <Link
      href={`${router.pathname}/[slug]`}
      as={`${router.pathname}/${slug}`}
      passHref
      legacyBehavior
    >
      <LinkArrow href={`${router.pathname}/${slug}`} border>
        {la.name}
      </LinkArrow>
    </Link>
  );
}
